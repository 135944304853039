@import url(https://fonts.googleapis.com/css?family=Roboto:500);
* {
  font-family: "Roboto", sans-serif;
}
.signin {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/we.webp");
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Roboto", sans-serif;
}
.div-container-row {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-container {
  background-color: rgba(255, 255, 255, 0.8);
  height: fit-content;
  border-radius: 5px;
  /* filter: blur(5px); */
}

.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  font-family: "Roboto", sans-serif;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.google-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.google-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto", sans-serif;
}
.google-btn:hover {
  box-shadow: 0 0 6px #4285f4;
  cursor: pointer;
}
.google-btn:active {
  background: #1669f2;
}
