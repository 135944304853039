.hero {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 17, 56, 0.6), rgba(0, 17, 56, 0.6)),
    url("../../assets/hero.webp");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px 0;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-wrapper {
  width: 55%;
}

.marquee {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  /* background-color: red; */
}

.marquee p {
  font-family: "Allura", cursive;
  will-change: transform;
  transform: translateX(0);
  white-space: nowrap;
  animation: marquee 20s linear infinite;
  font-size: 32px;
  font-style: italic;
  /* font-weight: 900; */
  color: white;
  margin: 0 20px;
}

.hero > h1 {
  font-family: "Baloo 2", cursive;
  font-size: 66px;
  width: 55%;
  color: #fff;
  text-align: center;
}
.hero > h1 > span {
  color: #00bf63;
}
.hero > p {
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #f5f5f7;
}
.hero > .learn-more-btn {
  padding: 10px 30px;
  color: #fff;
  background-color: #00bf63;
  border: none;
  border-radius: 6px;
}
.logo {
  position: absolute;
  width: 180px;
  height: auto;
  top: 10px;
  left: 10px;
}
@media (max-width: 992px) {
  .logo {
    width: 150px;
  }
}
@media (max-width: 576px) {
  .logo {
    width: 120px;
  }
}
@media (max-width: 1137px) {
  .hero > h1 {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .hero > h1 {
    font-size: 52px;
    width: 70%;
  }
  .hero > p {
    /* font-size: 17px; */
  }
}
@media (max-width: 703px) {
  .hero > h1 {
    font-size: 42px;
    width: 90%;
  }
  .hero > p {
    /* font-size: 17px; */
  }

  .marquee-wrapper {
    width: 90%;
  }

  .marquee p {
    font-size: 25px;
    margin: 0 5px;
  }
}
@media (max-width: 576px) {
  .hero {
    gap: 10px 0;
  }
  .hero > h1 {
    font-size: 36px;
    width: 95%;
  }
  .hero > p {
    font-size: 15px;
  }
}
