* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Baloo 2", cursive;

}
body,
html {
  overflow-x: hidden;
}
