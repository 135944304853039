h4 {
  font-size: 20px;
}
p {
  font-size: 16px;
}

p a {
  font-size: 16px;
  text-decoration: none;
  color: #fff;
}

a {
  text-decoration: none;
}

.copyright {
  text-align: center;
  font-size: 14px;
}
@media (max-width: 920px) {
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
}
.links:hover {
  cursor: pointer;
  color: #00bf63;
}
