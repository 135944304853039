.therapist-section {
  background-color: #fdf5f2;
  padding: 0 15px;
}
.therapist-left > img {
  width: 100%;
  border-radius: 5px;
}
.therapist-right > p {
  font-size: 24px;
  text-align: left;
  color: #353535;
}

.therapist-div > h3 {
  font-size: 35px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  font-family: "Baloo 2", cursive;
}

.therapistContentDiv {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.therapistContentDiv img {
  width: 40%;
  /* float: left; */
  border-radius: 8px;
  margin-right: 20px;
}

.therapistContentDiv p {
  font-weight: 300;
  font-size: 24px;
  font-family: "Baloo 2", cursive;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .therapist-div > h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .therapistContentDiv {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin-bottom: 40px;
  }

  .therapistContentDiv img {
    width: 90%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .therapistContentDiv p {
    font-size: 16px;
    padding: 0 5px;
  }
}

@media (max-width: 992px) {
  .therapist-div > h1 {
    font-size: 36px;
  }
  .therapist-right > p {
    padding-top: 15px;
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .therapist-div > h1 {
    font-size: 28px;
  }
  .therapist-right > p {
    font-size: 16px;
  }
}
.child-img-container {
  width: 100%;
}
.child-img-container > img {
  width: 100%;
}
