.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
}

.logo {
  filter: brightness(0) saturate(100%) invert(9%) sepia(98%) saturate(7486%)
    hue-rotate(198deg) brightness(93%) contrast(118%);

}

.formContainer {
  width: 40%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  padding: 30px 60px;
}

.formContainer form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.formContainer form h3 {
  font-weight: bold;
}

.formContainer form input {
  width: 90%;
  margin: 10px 5px;
}

.formContainer form button {
  width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .formContainer {
    width: 90%;
  }
}
