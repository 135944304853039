/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.homepage-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.content-div {
  margin: 150px;
}

.content-div h1 {
  text-align: center;
  margin: 30px 0;
  font-weight: bold;
}

.content-div ol li {
  margin: 10px 0;
}

.content-div ol li .list-header {
  margin-bottom: 0;
  /* font-size: 16px; */
  font-weight: bold;
  /* text-transform: uppercase; */
}

@media screen and (max-width: 768px) {
  .homepage-container {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .content-div {
    margin: 150px 50px;
  }

  .content-div h1 {
    text-align: center;
    margin: 30px 0;
  }

  .content-div ol li {
    margin: 2px 0;
    font-size: 14px;
  }

  .content-div ol li .list-header {
    margin-bottom: 0;
    /* font-size: 12px; */
    font-weight: bold;
    /* text-transform: uppercase; */
    color: black;
  }
}
