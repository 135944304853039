.program-section {
  width: 100%;
  background-color: #fdf5f2;
  position: relative;
}
.program-section > svg {
  width: 100%;
  position: absolute;
  /* z-index: 99; */
  top: -12vw;
  /* z-index: 9; */
}
.program {
  position: relative;
  z-index: 999999;
  padding: 40px 15px;
}

.program > h3 {
  font-size: 35px;
  margin-bottom: 50px;
  font-family: "Baloo 2", cursive;
  text-align: center;
}
.program-left > img {
  width: 90%;
}
.program-right > h5 {
  font-weight: 300;
  font-size: 24px;
  font-family: "Baloo 2", cursive;
}

.program-right ul li {
  font-weight: 300;
  font-size: 24px;
  font-family: "Baloo 2", cursive;
}

@media screen and (max-width: 768px) {
  .program > h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .program-section {
    padding: 0 10px;
  }
}

@media (max-width: 920px) {
  .program-right > h5 {
    font-size: 19px;
  }

  .program-right ul li {
    font-size: 19px;
  }
}
@media (max-width: 576px) {
  .program-right > h5 {
    font-size: 17px;
  }

  .program-right ul li {
    font-size: 17px;
  }
}
