.appointmentCard{
    /* display: flex;
    flex-wrap: wrap;
    gap: 0  10px;
    align-items: center;
    justify-content: center; */
    /* width: fit-content; */
    /* border: 1px solid #111; */
    /* padding: 10px 0;
    border: 1px solid #f5f5f7; */
}


.upcoming-appoinment{
   
}
.therapistImg{
    /* width: 100%;
    height: 50%; */
}
.row{
    /* height: 100%; */
}
.therapistImg{
    width: 100%;
    /* height: 50%; */
    aspect-ratio: 1/1;
    border-radius: 10px;
    max-height: 165px;
    max-width: 165px;
}
.imgContent{
    display: flex;
    align-items: flex-start;
    gap: 10px 20px;
    min-width: 285px;
    /* flex-wrap: wrap; */
}
.therapistImgDiv{
    width: 40%;
    max-width: 180px;
    max-height: 160px;
    cursor: pointer;
    /* min-width: 110px;
    min-height: 110px; */
}
.therapistContent{
    width: 50%;
}  
.therapistContent>p{
    margin-bottom: 10px;
}
.therapistContent>h5{
    cursor: pointer;
}
.dateTimeBtn{
    /* height: 95%; */
}
.btnGroup{
    gap: 10px 1.3vw;
    min-width: 270px;
    display: flex;
    justify-content: center;
    /* position: relative; */
    min-width: 325px;
} 
@media (max-width:682px) {
    .appointmentCard{
        display: flex;
        flex-direction: column;
        gap: 20px 0;
    }
    .therapistImg{
        width: 80%;
        
    }
    .imgContent{
        gap: 10px 0px;
        /* flex-wrap: wrap; */
    }
}
@media ((max-width:770px) and (min-width:670px)) {
    .appointmentCard{
        gap: 30px 0;
    }
}
.patientChartContainer {
    overflow-x: scroll;
}
.dateTime-btn{
    background-color: #f5f6f8;
    color: #000;
    border: none;
    border-radius: 10px;
    font-size: 19px;
    min-height: 55px;
    height: fit-content;
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.dateTime-btn p{
    margin: 0;
    font-size: 17px;
}
.join-btn , .action-btn{
    background-color: #00bf63;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 19px;
    /* width: 47%; */
    min-height: 40px;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.action-btn{
    background-color: #f5f6f8;
    color: #000;
    position: relative;
}
.bottomBtnDiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
   
    /* gap: 0 5px; */
}
.action-div{
    position: absolute;
    background-color: #f5f5f7;
    width: fit-content;
    /* width: 200px; */
    top: 50px;
    /* height: 100px; */
    width: 190px;
    border: 1px solid grey;
    z-index: 999;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px 0;
}
.action-div>hr{
    width: 100%;
    margin: 0;
    /* margin-bottom: 5px; */
}
.approve-btn{
    background-color: #00bf63;
    color:"#fff"
}
.disapprove-btn{
    background-color: #950202
}

