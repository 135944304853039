.patientImg {
  width: 100%;
  /* height: 180px; */
  aspect-ratio: 1/1;
  border-radius: 50%;
  /* min-width: 160px; */
}
@media (max-width: 1200px) {
  .patientImg {
    /* height: 160px; */
  }
}
.view-profile-btn {
  background-color: #1e45ac;
  border-radius: 3px;
  padding: 7px 30px;
  color: #fff;
}
.view-profile-btn:hover {
  background-color: #1e45ac;
  border-radius: 3px;
  padding: 7px 30px;
  color: #fff;
}
@media (max-width: 1200px) {
  .patientImg {
    height: 170px;
  }
}
@media (max-width: 920px) {
  .patientImg {
    height: 160px;
  }
}
@media (max-width: 574px) {
  .patientImg {
    height: 130px;
  }
}
